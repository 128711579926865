import { graphql } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { FixedObject } from 'gatsby-image';
import { Seo } from 'components/seo/seo';
import { CmsContext } from 'logic/context/cms';
import { App } from '../app/app';
import { JsonLdHelmet } from 'components/helmets/JsonLdHelmet';
import { toJsonLdFaqPage } from 'logic/json-ld/toJsonLdFaqPage';
import { ContentfulFaqMeta, Query } from '../../graphql-types';
import { findContentfulFaqMetaByPath, toFaqPageSpec } from 'logic/faq-meta/faq-new';
import { AppRoute } from 'app/app.route.const';
import { translate, translationKeys } from 'logic/translations/translations.service';

interface Image {
  description?: string;
  title?: string;
}

export interface FixedImage extends Image {
  fixed: FixedObject;
}

interface IndexProps {
  data: Query;
  location: any;
}

const IndexPage: FunctionComponent<IndexProps> = ({
  data: { contentfulHomeSingle, allContentfulFaqMeta },
}) => {
  const faqMetas: ContentfulFaqMeta[] = allContentfulFaqMeta.edges.map(({ node }) => {
    return { ...node };
  });

  const onlineTutoringFAQMeta = findContentfulFaqMetaByPath(faqMetas, AppRoute.OnlineTutoring);
  const onlineTutoringFAQ = toFaqPageSpec(onlineTutoringFAQMeta?.faq ?? []);

  return (
    <CmsContext.Provider
      value={{
        ...contentfulHomeSingle,
        faq: faqMetas,
        onlineTutoringFAQ,
      }}
    >
      <Seo
        title={translate(translationKeys.seoRoute[AppRoute.Home].title)}
        description={translate(translationKeys.seoRoute[AppRoute.Home].description)}
      />
      <JsonLdHelmet payload={toJsonLdFaqPage(onlineTutoringFAQ)} />
      <App />
    </CmsContext.Provider>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($language: String) {
    contentfulHomeSingle(node_locale: { eq: $language }) {
      pageMeta {
        metatitle
        metadescription
      }
      headingAboveSearch
      headingAboveSearchSecondLine
      video {
        videoID
        videoRatio
      }
    }
    allContentfulFaqMeta(filter: { node_locale: { eq: "de" } }) {
      edges {
        node {
          path
          faq {
            ... on ContentfulFaqMetaQuestion {
              question
              answer {
                answer
              }
            }
          }
        }
      }
    }
  }
`;
