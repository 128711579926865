import { ContentfulFaqMeta, ContentfulFaqMetaQuestion, Maybe } from '../../../graphql-types';
import marked from 'marked';

export interface FaqQuestionSpec {
  name: string;
  acceptedAnswer: string;
}

export interface FaqPageSpec {
  questions: FaqQuestionSpec[];
}

export const findContentfulFaqMetaByPath = (
  faqMetas: ContentfulFaqMeta[],
  path: string
): ContentfulFaqMeta | undefined => {
  return faqMetas.find((meta) => meta.path === path);
};

export const toFaqPageSpec = (faq: Maybe<ContentfulFaqMetaQuestion>[]): FaqPageSpec => {
  return { questions: faq?.flatMap(toFaqQuestionSpec) ?? [] };
};

const toFaqQuestionSpec = (question: Maybe<ContentfulFaqMetaQuestion>): FaqQuestionSpec[] =>
  question?.question && question?.answer?.answer
    ? [
        {
          name: question.question,
          acceptedAnswer: question.answer.answer,
        },
      ]
    : [];

export const faqQuestionMarkdownToHtml = (question: FaqQuestionSpec): FaqQuestionSpec => {
  const acceptedAnswer = marked(question.acceptedAnswer).replace(/"/g, "'");

  return { ...question, acceptedAnswer };
};
