import { FaqPageSpec, faqQuestionMarkdownToHtml, FaqQuestionSpec } from 'logic/faq-meta/faq-new';

export interface JsonLdQuestion {
  '@type': string;
  name: string;
  acceptedAnswer: { '@type': string; text: string };
}

export interface JsonLdFaqPage {
  mainEntity: JsonLdQuestion[];
  '@type': string;
  '@context': string;
}

export const toJsonLdFaqPage = (
  { questions }: FaqPageSpec,
  questionTransformer: (question: FaqQuestionSpec) => FaqQuestionSpec = faqQuestionMarkdownToHtml
): JsonLdFaqPage => {
  const questionMapper = questionTransformer
    ? (q: FaqQuestionSpec) => toJsonLdQuestion(questionTransformer(q))
    : (q: FaqQuestionSpec) => toJsonLdQuestion(q);

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map(questionMapper),
  };
};

const toJsonLdQuestion = ({ name, acceptedAnswer }: FaqQuestionSpec): JsonLdQuestion => {
  return {
    '@type': 'Question',
    name,
    acceptedAnswer: {
      '@type': 'Answer',
      text: acceptedAnswer,
    },
  };
};
